.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.section-title {
  font-size: 32px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 30px;
  padding-bottom: 20px;
  position: relative;
}
.section-title::after {
  content: "";
  position: absolute;
  display: block;
  width: 50px;
  height: 3px;
  background: #0092cb;
  bottom: 0;
  left: calc(50% - 25px);
}

.subsection-title {
  font-size: 22px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 15px;
}

.section-image {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}

li:hover > ul {
  visibility: visible; /* Make dropdown visible */
  opacity: 1; /* Make dropdown fully opaque */
  transform: translateY(0);
}

.nav-dropdown {
  visibility: hidden; /* Initially hide dropdown */
  opacity: 0; /* Initially make dropdown fully transparent */
  display: block; /* Keep it block to occupy space for smooth transition */
  position: absolute;
  background-color: #f9f9f9;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1;
  padding: 0;
  right: 0;
  list-style: none;
  width: auto;
  white-space: nowrap;
  border-radius: 10px;
  transform: translateY(70%);
  transition: transform 0.2s ease-out, opacity 0.2s ease-out, visibility 0s linear 0.2s; /* Add transition for opacity and delay hiding */
}

li:hover > .nav-dropdown {
  visibility: visible; /* Override to make visible on hover */
  opacity: 1; /* Make fully opaque on hover */
  transition-delay: 0s; /* Remove delay when showing */
}

.nav-dropdown > li:hover {
  background-color: #f1f1f1;
  border-radius: 10px;
}

.info-wrap {
  background-color: var(--surface-color);
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  border-top: 3px solid #47b2e4;
  border-bottom: 3px solid #47b2e4;
  padding: 30px;
  height: 100%;
}

.info-item {
  display: flex;
  align-items: center; /* Aligns items vertically in the center */
  gap: 10px; /* Optional: adds some space between the icon and the text */
  margin-bottom: 20px;
}

.info-item > i {
  font-size: 22px;
  background-color: aliceblue;
  border-radius: 50px;
  margin: 12px;
  width: 44px;
  height: 44px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

.img-scroll-container {
  display: flex;
  overflow: hidden;
  white-space: nowrap;
}

.img-scroll-content {
  display: flex;
  animation: scroll 40s linear infinite;
}

.img-scroll-content img {
  margin-right: 20px;
}